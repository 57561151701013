import type { Pathnames } from 'next-intl/navigation';
import { createLocalizedPathnamesNavigation } from 'next-intl/navigation';

import { locales } from './locales';

// The `pathnames` object holds pairs of internal
// and external paths, separated by locale.
export const pathnames = {
  // If all locales use the same pathname, a
  // single external path can be provided.
  '/dashboard': {
    de: '/',
    fr: '/',
  },

  // If locales use different paths, you can
  // specify each external path per locale.

  '/no-client': {
    de: '/kein-mandant-zugewiesen',
    fr: '/pas-de-mandant-attribue',
  },
  '/sign-in': {
    de: '/anmelden',
    fr: '/connexion',
  },
  '/sign-up': {
    de: '/registrieren',
    fr: '/inscrire',
  },
  '/sign-up-success': {
    de: '/registrierung-erfolgreich',
    fr: '/inscription-reussi',
  },
  '/reset-password': {
    de: '/passwort-zuruecksetzen',
    fr: '/reinitialiser-mot-de-passe',
  },
  '/personal-data': {
    de: '/persoenliche-daten',
    fr: '/donnees-personnelles',
  },
  '/orders': {
    de: '/bestellungen',
    fr: '/commandes',
  },
  '/orders/[[...order]]': {
    de: '/bestellungen/[[...order]]',
    fr: '/commandes/[[...order]]',
  },
  '/orders/[[...order]][[...orderPerSupplier]]': {
    de: '/bestellungen/[[...order]][[...orderPerSupplier]]',
    fr: '/commandes/[[...order]][[...orderPerSupplier]]',
  },
  '/shopping-cart': {
    de: '/warenkorb',
    fr: '/panier',
  },
  '/shopping-cart-success': {
    de: '/warenkorb-erfolgreich',
    fr: '/panier-reussi',
  },
  '/product-overview': {
    de: '/produktuebersicht',
    fr: '/apercu-du-produit',
  },
  '/product-overview/[[...search]]': {
    de: '/produktuebersicht/[[...search]]',
    fr: '/apercu-du-produit/[[...search]]',
  },
  '/help-and-support': {
    de: '/hilfe-und-support',
    fr: '/aide-et-support',
  },
} satisfies Pathnames<typeof locales>;

export const { Link, redirect, usePathname, useRouter, getPathname } =
  createLocalizedPathnamesNavigation({ locales, pathnames });
