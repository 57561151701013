'use client';

import { useLocale } from 'next-intl';
import { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';

import { locales } from '@/locales';
import { Link, usePathname } from '@/navigation';
import type { RouterOutputs } from '@/trpc/shared';

type Props = {
  config: RouterOutputs['config']['get'];
  highlightActive?: boolean;
};

export const LanguageSwitchPublicRoutes = ({
  config,
  highlightActive = true,
}: Props) => {
  // Hooks.
  const pathname = usePathname();
  const locale = useLocale();

  if (config.locales.length < 2) {
    return null;
  }

  return (
    <div className="flex justify-center px-4 py-12 sm:px-6 lg:px-20 xl:px-24">
      {locales.map((l, index) => (
        <Fragment key={`language-switch-public-routes-${l}-${index}`}>
          <Link
            href={pathname}
            locale={l}
            className={twMerge(
              'text-gray-500 hover:text-gray-400 transition duration-150 ease-in-out',
              highlightActive && locale === l && 'text-gray-900',
            )}
          >
            {l.toUpperCase()}
          </Link>
          {index < locales.length - 1 && (
            <span className="mx-2 text-gray-400">|</span>
          )}
        </Fragment>
      ))}
    </div>
  );
};
